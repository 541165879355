'use client';
import { useState } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image'; 

const SearchBar = ({ inputClassName = '' }) => {
  const [query, setQuery] = useState(''); 
  const router = useRouter(); 

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && query.trim() !== '') {
      router.push(`/search?query=${encodeURIComponent(query)}`); 
    }
  };

  return (
    <div className={`relative w-full max-w-md px-4 ${inputClassName}`}>
      {/* 虫眼鏡アイコンを検索バー内に表示 */}
      <span className="absolute left-4 top-1/2 transform -translate-y-1/2  px-4">
        <Image src="/images/search.png" alt="Search Icon" width={16} height={16} />
      </span>

      <input
        type="text"
        placeholder="検索する"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        className="w-full pl-12 p-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
      />
    </div>
  );
};

export default SearchBar;