'use client';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import Image from 'next/image';
import SideMenu from './SideMenu';
import { useAuth } from './AuthContext';
import Link from 'next/link';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNotificationClick = () => {
    if (isLoggedIn) {
      router.push('/notifications');
    } else {
      alert('通知を確認するにはログインが必要です。');
    }
  };

  return (
    <header className="w-full flex justify-between items-center p-4 bg-white shadow-md">
      {/* 左側のサイドメニューボタン */}
      <div className="flex items-center">
        <Image
          src="/images/menu-icon.png"
          alt="Menu Icon"
          width={24}
          height={24}
          className="cursor-pointer"
          onClick={toggleMenu}
        />
      </div>

      {/* 中央のロゴ */}
      <div className="flex items-center justify-center">
        <Link href="/">
          <Image src="/images/Kosumiru.png" alt="サイトロゴ" width={144} height={32} />
        </Link>
      </div>

      {/* 右側の通知アイコン */}
      <div className="flex items-center">
        <Image
          src="/images/notification.png"
          alt="Notification Icon"
          width={24}
          height={24}
          className="cursor-pointer"
          onClick={handleNotificationClick}
        />
      </div>

      {/* サイドメニューを表示 */}
      <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </header>
  );
};

export default Header;
